import React from "react";
import type { AppProps /*, AppContext */ } from "next/app";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../lib/theme";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { useApollo } from "../src/utils/apolloClient";
import dynamic from "next/dynamic";
import "../styles/global.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/material-icons/index.css";
import { store, persistor } from "../store/main";
import { PersistGate } from "redux-persist/integration/react";
import NextNProgress from "nextjs-progressbar";
import Script from "next/script";
import { GoogleTagManager } from "@next/third-parties/google";
const DefaultLayout = dynamic(
  () => import("../components/layouts/DefaultLayout")
);

const MyApp = ({ Component, pageProps }: AppProps) => {
  const apolloClient = useApollo(pageProps);
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.Fragment>
      <NextNProgress />
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, maximum-scale=5 width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
      </Head>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <PersistGate
              loading={<Component {...pageProps} />}
              persistor={persistor}
            >
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <DefaultLayout>
                <Component {...pageProps} />
              </DefaultLayout>
            </PersistGate>
          </ThemeProvider>
        </ApolloProvider>
      </Provider>
      {process.env.NEXT_PUBLIC_GTM_ENABLE == "true" && (
        <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM} />
      )}
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=95e4176b-0acf-41ea-91e1-d072ab6b116b"
        strategy="worker"
      />
      <Script
        id="popupsmart"
        src="https://cdn.popupsmart.com/bundle.js"
        data-id="784199"
        strategy="worker"
        async
        defer
      />
    </React.Fragment>
  );
};

export default MyApp;
